@import "palette/palette";
@import "components/hero";

@font-face {
    font-family: Quantum;
    src: url(../fonts/Quantum.otf);
  }
  

*, *::after, *::before {
    margin: 0;
    box-sizing: border-box;
    font-family: Quantum;
    user-select: none;
    padding: 0;
}

.App {
    background-color: color(base, night-blue);
    height: 100vh;
}

.launcher {
    background-color: color(base, night-blue);
    height: 100vh;
    max-height: 100vh;
    width: 100%;

    
    transition: 1s;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.launcher-top {
    max-height: 10vh;

}

